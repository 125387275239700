<template>
  <div class="inline yes-no-optional">
    <img
      v-show="expr === 'Yes' || expr === 'Optional'"
      src="~Images/check.webp"
    />
    <span v-show="expr === 'Optional'" class="optional">(optional)</span>
    <img v-show="expr === 'No'" src="~Images/no.png" />
  </div>
</template>
<script>
export default {
  props: ["expr"],
};
</script>
<style lang="scss" scoped>
.optional {
  font-style: italic;
  font-weight: normal;
  font-size: 0.9em;
  top: 2px;
  left: 5px;
  position: relative;

  @include media-breakpoint-down(sm) {
    font-size: 0.75em;
    left: 2px;
    top: 0;
  }
}

</style>